.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 15px;
}

.login-button {
  background-color: #1db954;
  padding: 20px 45px;
  color: white;
  text-decoration: none;
  border-radius: 40px
}

.user-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.user-info div {
  font-size: 16px;
  margin-left: 15px;
  align-self: flex-start;
}

.container p {
  margin: 20px 0px;
}

.container h1 {
  margin-top: 50px;
}

.list {
  display: flex;
  justify-content: space-between;
  overflow-y: scroll;
}
.list-column a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.list a, .list-column a {
  margin: 10px;
  text-decoration: none;
  color: white;
  text-align: center;
}

.display-info {
  display: flex;
  justify-content: center;
}

.display-info div {
  font-size: 18px;
  margin-left: 15px;
  align-self: flex-start;
}

.tracks {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.tracks p {
  font-size: medium;
  margin: 0px 25px;
}

.tracks-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.album-tracks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 18px;
  margin-top: 50px;
}

.album-track {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.album-track p {
  margin-right: 20px;
}

.albums-list {
  display: flex;
  flex-direction: column;
  font-size: medium;
} 

.albums-list a {
  margin: 10px 0px;
  text-decoration: none;
} 

.albums-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.albums-list-item p {
  color: white;
}